import React from "react"
import {graphql} from "gatsby"
import Seo from "../components/_shared/Seo"
import SliceZone from "../components/SliceZone"

const Page = ({data}) => {

    if (!data) return null

    const pageContent = data.prismicPage || {};

    return (
        <>
            <SliceZone
                body={pageContent.data.body}
                page={pageContent.uid}
            />
        </>
    );
};

export const Head = ({ data }) => (
    <Seo title={data.prismicPage.data.seo_title}
         description={data.prismicPage.data.seo_description}
         keywords={data.prismicPage.data.seo_keywords}/>
);

export const query = graphql`
  query PageQuery($id: String, $lang: String) {
    prismicPage(id: { eq: $id }, lang: { eq: $lang }) {
        id
        uid
        lang
        data {
          page_title {
            richText
          }
          seo_title
          seo_description
          seo_keywords
          body {
          
          ... on PrismicPageDataBodyLinkButtons {
              id
              slice_type
              primary {
                description {
                  richText
                }
              }
              items {
                link_url
                link_name
              }
            }
            
            ... on PrismicPageDataBodyHero {
              id
              slice_type
              primary {
                hero_title {
                  richText
                }
                hero_content
                background_image {
                  fluid {
                    src
                  }
                }
                content_type
                content_link {
                  url
                }
              }
            }
            ... on PrismicPageDataBodyInfocard {
              id
              slice_type
              primary {
                section_title {
                  richText
                }
              }
              items {
                title {
                  richText
                }
                image {
                  fluid {
                    src
                  }
                }
                content {
                  richText
                }
              }
            }
            ... on PrismicPageDataBodyImageTextSection {
                id
                slice_type
                primary {
                  image_position
                  title {
                    richText
                  }
                  content {
                    richText
                  }
                  image {
                    fluid {
                      src
                    }
                  }
                  image_text {
                    richText
                  }
                }
            }
                
            ... on PrismicPageDataBodyTextSection {
              id
              slice_type
              primary {
                section_title {
                  richText
                }
                section_text {
                  richText
                }
              }
              items {
                image {
                  fluid {
                    src
                  }
                }
                image_title {
                  richText
                }
                image_text {
                  richText
                }
              }
            }
          }
          
        }
    }
  }
`

export default Page



